import type { mastodon } from '#backend/types'
import { ASPECT_RATIOS_PREFIXES, type PresetPrefix } from '#media/image-kit'

type AspectRatio = number | string | null

export function gridSizeToPresetPrefix(gridSize?: mastodon.v1.PreviewCardGridSize) {
  return (!gridSize || gridSize === 'large') ? 'square' : gridSize
}

export function aspectRatioToPresetPrefix(aspectRatio: AspectRatio) {
  aspectRatio = parseFraction(aspectRatio)
  if (!aspectRatio) return null

  let closestPrefix: PresetPrefix | null = null
  let smallestDifference = Infinity

  for (const [prefix, ratio] of Object.entries(ASPECT_RATIOS_PREFIXES)) {
    if (ratio === undefined) continue
    const difference = Math.abs(ratio - aspectRatio)
    if (difference < smallestDifference) {
      smallestDifference = difference
      closestPrefix = prefix as PresetPrefix
    }
  }
  return closestPrefix
}

export function parseFraction(fraction: AspectRatio): number | null {
  if (typeof fraction !== 'string') return fraction
  const [numerator, denominator] = fraction.replace(/\s/g, '').split('/')
  const num = Number(numerator)
  const den = Number(denominator)
  if (isNaN(num) || isNaN(den) || den === 0) {
    return null
  }
  return Number((num / den).toFixed(4))
}
