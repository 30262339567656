<script setup lang="ts">
import { useFluent } from 'fluent-vue'
import type { mastodon } from '#types'
import type { MenuItem } from '~/types'

const { $t } = useFluent()

const props = defineProps<{
  status: mastodon.v1.Status
  hover?: boolean
}>()

const isSelf = useSelfAccount(() => props.status.account)

const popupSizes = ref()
const cardSizeTemp = ref()

const cardSize = computed({
  get: () => typeof cardSizeTemp.value !== 'undefined' ? cardSizeTemp.value : props.status.card?.gridSize,
  set: (newVal) => {
    cardSizeTemp.value = newVal
  },
})

const contentNode = computed(() => {
  const desc = props.status?.card?.description
  if (!desc) {
    return undefined
  }
  return contentToVNode(desc)
})

const menuItems: MenuItem[] = [
  {
    icon: 'trash',
    label: $t('menu-delete'),
    command: () => $fetch(`/api/statuses/${props.status.id}`, { method: 'DELETE' }),
  },
  {
    icon: 'pencil',
    label: $t('action-edit'),
    command: undefined, // () => $fetch(`/api/statuses/${props.status.id}`, { method: 'PUT', body: { gridSize: undefined } })
  },
]

function confirmCardSize(size?: string) {
  cardSizeTemp.value = size ?? null
  popupSizes.value?.close()
}

function openComments() {
  useRouter().push({ path: useRoute().path, query: { s: props.status.id } })
}
</script>

<template>
  <div class="status-wrap" :class="cardSize">
    <StatusLink :status="status" :hover="hover" :class="cardSize" class="post-preview-cell post-preview-link">
      <div class="status-card-header">
        <UiBar corner text class="desc">
          <StatusDate :status="status" />
        </UiBar>
        <UiBar v-if="isSelf" corner>
          <UiPopup ref="popupSizes" theme="indi-button-group" class="shy" placement="top">
            <template #trigger-title>
              <span class="icon-dimensions" />
            </template>
            <StatusGridSizeMenu v-model:size="cardSize" :status-id="status.id" @sett="confirmCardSize" />
          </UiPopup>
          <UiPopupMenu :items="menuItems" />
        </UiBar>
      </div>
      <div v-if="status.card?.image" class="status-media" :class="{ expanded: !contentNode }">
        <UiImg :src="status.card?.image" :orientation="gridSizeToPresetPrefix(cardSize)" />
      </div>
      <div v-if="!status.card?.image && status.card?.wave" class="status-media wave" :class="{ expanded: !contentNode }">
        <StatusWaveform :wave="status.card.wave" />
      </div>
      <div v-if="contentNode" class="excerpt truncate" :class="{ 'mixed-content': status.card?.image }">
        <Component :is="contentNode" />
      </div>
      <UiBar corner class="status-social">
        <StatusSocialBar :status="status" @require-reply="openComments" />
      </UiBar>
    </StatusLink>
  </div>
</template>

<style>
.status-wrap {
  --ui-filter-blur: 50px;
  --color-text: var(--color-panel-text);
  container-type: inline-size;
  border-radius: var(--corner-radius);
  overflow: clip;

  .ui-bar {
    --bar-opacity: 20%;
  }

  button.shy,
  .shy button {
    transition: width 0.15s, opacity 0.15s;
    min-width: 0;
    width: 0;
    opacity: 0;
  }

  &:hover {
    --transit-duration: 0.1s;
    position: relative;
    z-index: 1;

    .ui-bar {
      --bar-opacity: var(--ui-opacity);
    }

    button.shy,
    .shy button {
      width: var(--button-height);
      opacity: 1;
    }
  }

  .excerpt {
    --truncate-lines: 6;

    &.mixed-content {
      --truncate-lines: 3;
    }
  }

  &.vertical,
  &.big {
    @media (height > calc(480px + 3rem)) {
      .excerpt:not(.mixed-content) {
        --truncate-lines: 18;
      }
    }
  }
}

.post-preview-cell {
  --transit-duration: 0.4s;
  position: relative;
  display: grid;
  grid-template-rows: var(--button-height) 1fr auto var(--button-height);

  width: 100%;
  height: 100%;
  background-color: var(--color-panel);
  color: black;
  transition:
    transform var(--transit-duration) ease-in-out,
    background-color 0.1s,
    border-radius 0.2s,
    box-shadow var(--transit-duration);

  .post-preview-link {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;

  }

  .status-card-header {
    grid-row: 1;
    grid-column: 1;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &> :first-child {
      border-radius: 0;
      border-end-end-radius: calc(var(--corner-radius) / 2);
    }

    &> :last-child {
      border-radius: 0;
      border-end-start-radius: calc(var(--corner-radius) / 2);
    }
  }

  .status-media {
    grid-row: 1 / 3;
    grid-column: 1;

    &.expanded {
      grid-row: 1 / -1;
    }

    &.wave {
      display: flex;
      align-items: center;
    }

    img,
    .px-img.empty {
      width: 100%;
      height: 100%;
    }
  }

  .excerpt {
    height: fit-content;
    padding-inline: var(--padding-medium);
    margin-block: var(--padding-small);
    max-width: 100cqi;
    word-break: break-word;
    hyphens: auto;
    place-self: center;

    p,
    h1,
    h2,
    h3 {
      margin: 0
    }

    &.mixed-content {
      place-self: flex-start;
      font-size: var(--font-size--1);
      --h3: var(--font-size-0);
      --h2: var(--font-size-1);
      --h1: var(--font-size-2);
    }
  }

  .status-social {
    grid-row: 4;
    grid-column: 1;
    place-self: flex-start;
    border-radius: 0;
    border-start-end-radius: calc(var(--corner-radius) / 2);
  }
}
</style>
