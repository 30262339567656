<script setup lang="ts">
defineProps<{
  open?: boolean
  src: string
  aspectRatio?: number | string
}>()
const emit = defineEmits(['request-close'])
</script>

<template>
  <UiPopoverWidget
    css-class="content-image-viewer-pop"
    :css-style="{ '--aspect-ratio': `${aspectRatio}` }"
    :open="open"
    @request-close="emit('request-close')">
    <UiImg
      :src="src"
      :aspect-ratio="aspectRatio"
      @click="emit('request-close')"
    />
  </UiPopoverWidget>
</template>

<style lang='scss'>
.content-image-viewer-pop {
  --popover-width: 100%;
  --popover-height: 100%;
  .modal-shadow {
    background-color: rgb(0 0 0 / 0.85);
  }
  .popover-modal {
    width: fit-content;
    height: fit-content;
    max-height: unset;
    max-width: unset;
    background-color: transparent;

    .popover-header {
      position: absolute;
    }
    .popover-content {
      --max-h: calc(100dvh - var(--popup-margin-top) * 2);
      aspect-ratio: var(--aspect-ratio, 4 / 3);
      width: min((100vw - var(--padding-small) * 2), var(--max-h) * var(--aspect-ratio));
      max-height: var(--max-h);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
