<script setup lang="ts">
import { bodyScrollGuard } from '@/lib/control-body-scroll'

const props = withDefaults(defineProps<{
  open?: boolean
  disableClickOutside?: boolean
  cssClass?: string
  cssStyle?: Record<string, string>
}>(), {
  open: false,
  disableClickOutside: false,
  cssClass: undefined,
})

const { disableClickOutside } = toRefs(props)
const emit = defineEmits(['request-close'])

onMounted(() => {
  window.addEventListener('keyup', handleKeyup)
  bodyScrollGuard.freezeBodyScroll()
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeyup)
  bodyScrollGuard.releaseBodyScroll()
})

function handleKeyup(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    requestClose('esc')
  }
}

function requestCloseOutside() {
  if (disableClickOutside.value) {
    return
  }
  requestClose('outside')
}
function requestCloseButton() {
  requestClose()
}

function requestClose(trigger?: string) {
  emit('request-close', trigger)
}

watch(
  () => props.open,
  (val) => {
    if (val) {
      bodyScrollGuard.freezeBodyScroll()
    }
    else {
      bodyScrollGuard.releaseBodyScroll()
    }
  },
)
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="open" class="popover-widget-wrap theme" :class="cssClass" :style="cssStyle">
        <div class="modal-shadow" @click="requestCloseOutside" />
        <div class="popover-modal">
          <header class="popover-header">
            <div class="left">
              <slot name="header-title">
                <!---->
              </slot>
              <slot name="header-left" />
            </div>
            <div class="right">
              <slot name="header-right" />
              <button class="compact" @click="requestCloseButton">
                <span class="icon-close" />
              </button>
            </div>
          </header>
          <div class="popover-content">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.popover-widget-wrap {
  --content-width: min(calc(var(--content-max-text-width)), 100cqi);
  --pop-bg: var(--color-panel-minor);
  position: fixed;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: var(--popup-margin-top);
  z-index: calc(var(--z-index-modal) + 1000);

  .modal-shadow {
    position: fixed;
    height: calc(100vh + 10px);
    width: calc(100vw + 10px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-popover-shadow-light);
    z-index: 0;
  }

  .popover-modal {
    --popover-header-h: var(--base-size);
    position: relative;
    width: var(--popover-width, var(--content-width));
    max-height: calc(var(--win-height, 100svh) - var(--popup-margin-top) - var(--popup-margin-bottom));
    max-width: calc(100vw - var(--popup-margin-side) * 2);
    min-height: calc(var(--base-size) * 2);
    min-width: calc(var(--base-size) * 5);
    border-radius: var(--corner-radius);
    box-shadow: 0 0 0 1px var(--color-line-light);
    box-sizing: content-box;
    background-color: var(--pop-bg);
    overflow: auto;
    overscroll-behavior: contain;
    z-index: 1;

    .popover-header {
      position: sticky;
      top: 0;
      right: 0;
      height: var(--popover-header-h);
      display: flex;
      align-items: center;
      padding-inline: var(--padding-small);
      z-index: 200;
      width: 100%;

      .left {
        padding-left: var(--padding-base);
        margin-right: auto;
        flex-grow: 1;
      }

      .right {
        display: flex;
        margin-left: auto;
        flex-shrink: 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
