<script setup lang="ts">
import { sizes as presetSizes, type PresetPrefix } from '#media/image-kit'

const props = defineProps<{
  src: string
  alt?: string
  aspectRatio?: string | number
  square?: boolean
  orientation?: PresetPrefix
  sizes?: string
}>()
const urlBase = 'https://individuallist.xyz/media/'
const fileName = computed(() => props.src.split('/').pop())

const imgRef = ref<HTMLImageElement | null>(null)
const loaded = ref(false)
const error = ref(false)

const srcStr = computed(() => {
  if (props.src.startsWith('data:') || props.src.startsWith('blob:')) {
    return props.src
  }
  return !loaded.value ? sizePresetUrl('placeholder') : sizePresetUrl(800)
})

const srcSetStr = computed(() => {
  if (props.src.startsWith('data:') || props.src.startsWith('blob:')) {
    return
  }
  if (!loaded.value) {
    return
  }
  return presetSizes.map(size => `${sizePresetUrl(size)} ${size}w`).join(', ')
})

function sizePresetUrl(preset: number | string) {
  let prefix = props.orientation && props.orientation !== 'normal' ? `${props.orientation}-` : ''
  if (!prefix && props.square) {
    prefix = 'square-'
  }
  if (!prefix && props.aspectRatio) {
    const pre = aspectRatioToPresetPrefix(props.aspectRatio)
    prefix = pre ? `${pre}-` : ''
  }
  return `${urlBase}${prefix}${preset}/${fileName.value}`
}

const sizesStr = computed(() => {
  return props.sizes || '800px'
})

const inlineStyle = computed(() => {
  if (!props.aspectRatio) {
    return
  }
  return { 'aspect-ratio': props.aspectRatio }
})

function checkIfLoaded() {
  if (imgRef.value?.complete) {
    loaded.value = true
    error.value = false
  }
}
onMounted(() => checkIfLoaded())

const onLoad = () => {
  loaded.value = true
  error.value = false
}
function onError() {
  error.value = true
}
defineExpose({
  el: imgRef,
})
</script>

<template>
  <img
    v-if="!error"
    ref="imgRef"
    class="px-img"
    :class="{ loaded }"
    :srcset="srcSetStr"
    :sizes="sizesStr"
    :src="srcStr"
    :alt="alt"
    :style="inlineStyle"
    loading="lazy"
    @load="onLoad"
    @error="onError">
  <div v-else class="px-img empty" />
</template>

<style scoped lang="scss">
.px-img {
  max-width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s;
  display: block;
  &.lazyLoad {
    opacity: 0;
  }

  &.loaded {
    opacity: 1;
  }

  &.empty {
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 1px;
      background-color: color-mix(in lab, var(--color-text) 33%, transparent);
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
</style>
