<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  status: mastodon.v1.Status
}>()
const card = computed(() => props.status.card)
const imageOrientation = ref()

const contentNode = computed(() => {
  const desc = card.value?.description
  if (!desc) {
    return undefined
  }
  return contentToVNode(desc)
})
</script>

<template>
  <div class="status-mini-card">
    <AccountBadge :account="props.status.account">
      <template #meta>
        <StatusDate :status="props.status" />
      </template>
    </AccountBadge>
    <div class="post-preview" :class="{ 'mixed-content': status.card?.image }">
      <UiImg
        v-if="card?.image"
        :src="card?.image"
        :orientation="imageOrientation"
        sizes="100px"
        class="post-preview-attachment"
      />
      <div v-if="contentNode" class="excerpt truncate">
        <Component :is="contentNode" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/mixins";

.status-mini-card {
  --_img-size: calc(var(--base-size) * 1.25);
  --truncate-lines: 3;
  --button-height: var(--badge-size);
  .account-info .desc {
    font-size: var(--font-size-micro);
  }
  .post-preview {
    margin-top: var(--padding-small);
    padding-inline-start: calc(var(--badge-size) + var(--padding-small));
    &.mixed-content {
      display: grid;
      grid-template-columns: var(--_img-size) 1fr;
      gap: var(--padding-small);
    }
  }
  .post-preview-attachment {
    width: var(--_img-size);
    height: var(--_img-size);
  }
  .excerpt {
    h1, h2, h3, h4 {
      font-size: var(--font-size-body);
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}
</style>
