<script setup lang="ts">
import { bodyScrollGuard } from '@/lib/control-body-scroll'

const props = withDefaults(defineProps<{
  open?: boolean
  disableClickOutside?: boolean
}>(), { open: undefined })

const emit = defineEmits(['request-close'])
const autoOpen = ref(true)

const { account: acct } = useRoute().params
const account = acct ? await fetchAccountByHandle(`${acct}`) : null
const theme = useTheme(computed(() => account?.theme))

onMounted(() => {
  window.addEventListener('keydown', handleKeyup)
  bodyScrollGuard.freezeBodyScroll()
  if (props.open === undefined) {
    autoOpen.value = true
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyup)
  bodyScrollGuard.releaseBodyScroll()
})

function handleKeyup(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    requestClose('esc')
  }
}

function requestCloseOutside() {
  if (props.disableClickOutside) {
    return
  }
  requestClose('outside')
}

function requestClose(trigger?: string) {
  emit('request-close', trigger)
}
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="props.open ?? autoOpen"
        class="popover-modal-wrap theme theme-panel theme"
        :style="theme">
        <div class="modal-shadow" @click="requestCloseOutside" />
        <div class="popover-overlays">
          <div class="popover-modal">
            <header class="popover-header">
              <div class="left">
                <slot name="header" />
              </div>
              <div class="right">
                <slot name="header-right" />
                <PortalTarget name="modal-header" />
                <button class="compact" @click="requestClose()">
                  <span class="icon-close" />
                </button>
              </div>
            </header>
            <div class="popover-content">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<!-- suppress CssInvalidAtRule -->
<style lang="scss">
:root {
  --popup-margin-top: var(--padding-small);
  --popup-margin-bottom: var(--padding-small);
  --popup-margin-side: calc(var(--base-size) + var(--padding-big));

  @media (max-width: 540px) {
    --popup-margin-side: var(--padding-base);
  }
}

.popover-modal-wrap {
  --content-width: min(calc(var(--content-max-text-width) + var(--popup-margin-side) * 2), calc(100cqi - var(--padding-base)));
  --popover-max-height: calc(100svh - var(--popup-margin-top) - var(--popup-margin-bottom));
  position: fixed;
  width: 100vw;
  height: var(--win-height, 100vh);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-block: var(--popup-margin-top);
  z-index: var(--z-index-modal);

  .modal-shadow {
    position: fixed;
    height: calc(100vh + 10px);
    width: calc(100vw + 10px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-popover-shadow-light);
    z-index: 0;
  }
  .popover-modal {
    --color-text: var(--color-panel-text);
    --popover-header-h: var(--base-size);
    position: relative;
    max-width: calc(100vw - var(--padding-base));
    max-height: var(--popover-max-height);
    min-height: calc(var(--base-size) * 2);
    height: fit-content;
    border-radius: var(--corner-radius);
    box-shadow: 0 0 0 1px var(--color-line-light);
    background-color: var(--color-panel);
    color: var(--color-text);
    overflow: auto;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
    z-index: 1;

    .popover-header {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      right: 0;
      height: var(--popover-header-h);
      padding-inline: var(--padding-small);
      z-index: 200;
      width: min(100%, var(--content-width));

      .left {
        display: flex;
        margin-right: auto;
        flex-grow: 1;
      }

      .right {
        display: flex;
        margin-left: auto;
        flex-shrink: 0;
      }
    }
  }

  .popover-overlays {
    position: relative;

    &:after {
      --frame-width: calc(var(--padding-mini) * 0.75);
      content: '';
      display: block;
      position: absolute;
      inset: var(--frame-width);
      pointer-events: none;
      box-sizing: border-box;
      border-width: var(--frame-width);
      border-color: color-mix(in lab, var(--color-bg), var(--color-panel));
      border-style: dashed;
      border-radius: calc(var(--corner-radius) - var(--frame-width));
      mix-blend-mode: exclusion;
      opacity: 0;
      transition: opacity 0.2s;
      z-index: 1;
    }

    &:has(.editable):after {
      opacity: 0.3;
      transition: opacity 0.4s;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
