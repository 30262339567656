<script setup lang="ts">
import type { ContentMediaImage } from '~/types'
import type UiImg from '~/components/ui/UiImg.vue'

const props = defineProps<{
  media: ContentMediaImage
  aspectRatioLimit?: number | string // for instance '9 / 16'
}>()

const img = ref<InstanceType<typeof UiImg>>()
const allowUpscale = ref(false)
const openViewer = ref(false)

const aspectRatio = computed(() => {
  const limit = parseFraction(props.aspectRatioLimit ?? '')
  const aspect = props.media.aspect
  if (!limit) return aspect
  if (!aspect) return limit
  const isTollerThenImage = limit > aspect
  return isTollerThenImage ? limit : aspect
})

onMounted(() => {
  setAllowUpscale()
  addEventListener('resize', setAllowUpscale)
  onBeforeUnmount(() => {
    removeEventListener('resize', setAllowUpscale)
  })
})

function setAllowUpscale() {
  allowUpscale.value = makeSenseToUpscale()
}
function makeSenseToUpscale() {
  if (!img.value?.el) {
    return false
  }
  const minFact = 1.2
  const { width: currentWidth, height: currentHeight } = img.value.el.getBoundingClientRect()
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window
  const aspectRatio = props.media.aspect ?? 1

  // Calculate potential fullscreen dimensions while maintaining aspect ratio
  let fullscreenWidth, fullscreenHeight

  // If screen aspect ratio is wider than image
  if (screenWidth / screenHeight > aspectRatio) {
    // Height will be the limiting factor
    fullscreenHeight = screenHeight
    fullscreenWidth = screenHeight * aspectRatio
  }
  else {
    // Width will be the limiting factor
    fullscreenWidth = screenWidth
    fullscreenHeight = screenWidth / aspectRatio
  }
  // Only allow upscale if fullscreen would be significantly larger
  return (fullscreenWidth > currentWidth * minFact)
    || (fullscreenHeight > currentHeight * minFact)
}
</script>

<template>
  <UiImg
    ref="img"
    :src="media.src"
    :aspect-ratio="aspectRatio"
    class="full-width-content"
    @click="openViewer = true"
  />
  <ContentImageViewerPop
    v-if="allowUpscale"
    :open="openViewer"
    :src="media.src"
    :aspect-ratio="media.aspect ?? 1"
    @request-close="openViewer = false"
  />
</template>

<style lang='scss'>
.content-image {
  position: relative;
}
</style>
